import filter from "lodash.filter"
import { useEffect, useMemo, useState } from "react"
import { useLanguage } from "./useLanguage"

export default function useFilter(toFilter, possibleFilters) {
    const { switchLanguage } = useLanguage()
    const all = switchLanguage("すべて", "All")
    const [currentFilter, setCurrentFilter] = useState(all)

    useEffect(() => {
        setCurrentFilter(all)
    }, [all])

    const filters = useMemo(() => {
        return [{ name: all }, ...possibleFilters]
    }, [possibleFilters, all])

    const filterListBy = useMemo(() => {
        return createFilterableList(toFilter, filterLogic, setCurrentFilter)
    }, [toFilter])

    const filteredList = useMemo(() => {
        return filterListBy(currentFilter)
    }, [currentFilter, filterListBy])

    return { possibleFilters: filters, filteredList, currentFilter, setCurrentFilter }
}

function filterLogic(appliedFilter) {
    return ({ category }) => {
        return category.name === appliedFilter || category.nameJp === appliedFilter
    }
}

function createFilterableList(list, fn, assignFilter) {
    return appliedFilter => {
        assignFilter(appliedFilter)

        if (checkIfAll(appliedFilter)) {
            return list
        }

        return filter(list, fn(appliedFilter))
    }
}

function checkIfAll(appliedFilter) {
    const normalisedFilter = normaliseFilter(appliedFilter)
    return normalisedFilter === "all" || normalisedFilter === "" || normalisedFilter === "すべて".toLowerCase()
}

function normaliseFilter(filter) {
    return filter.toLowerCase().replaceAll(" ", "")
}
