import { graphql } from "gatsby"
import React from "react"
import ChangeHeaderInView from "../components/changeHeaderinView"
import FilterNews from "../components/filterNews"
import Layout from "../components/layout"
import PageNavigation from "../components/pageNavigation"
import Seo from "../components/seo"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"

export default function News({ data }) {
    const { switchLanguage } = useLanguage()
    return (
        <Layout>
            <Seo title='News' />
            <ChangeHeaderInView threshold={0} inital={true} color={COLOR_STATES.DARK}>
                <PageNavigation />
                <FilterNews news={data.news.nodes} filters={data.filters.nodes} showing={switchLanguage(data.pageData.showingJp, data.pageData.showing)} articles={switchLanguage(data.pageData.articlesJp, data.pageData.articles)} allArticles={switchLanguage(data.pageData.allArticlesJp, data.pageData.allArticles)} />
            </ChangeHeaderInView>
        </Layout>
    )
}

export const query = graphql`
    query News {
        pageData: datoCmsNewsPage {
            subtitle
            subtitleJp
            showing
            showingJp
            articles
            articlesJp
            allArticles
            allArticlesJp
        }
        news: allDatoCmsNews {
            nodes {
                ...NewsCard
            }
        }
        filters: allDatoCmsCategory {
            nodes {
                name
                nameJp
            }
        }
        featuredNews: allDatoCmsNews(sort: { fields: meta___createdAt, order: ASC }, limit: 1) {
            nodes {
                name
                slug
                meta {
                    createdAt
                }
                videoUrl
                image {
                    gatsbyImageData
                }
                category {
                    image {
                        url
                    }
                    name
                }
            }
        }
    }
`
