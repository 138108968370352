import React, { memo, useCallback, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import useFilter from "../hooks/useFilter"
import { useLanguage } from "../hooks/useLanguage"
import { ReactComponent as Arrow } from "../images/arrowdown.svg"
import { H3, P } from "./fonts"
import NewsCard from "./newsCard"
export default function FilterNews({ news, filters, showing, articles, allArticles }) {
    const { possibleFilters, filteredList, setCurrentFilter, currentFilter } = useFilter(news, filters)

    return (
        <div className='max-1600 ma pt160 pb160 pl60 pr60 m-pl20 m-pr20 m-pt140px m-pb60 '>
            <Filters possibleFilters={possibleFilters} setCurrentFilter={setCurrentFilter} currentFilter={currentFilter} articlesLength={filteredList.length} showing={showing} articles={articles} allArticles={allArticles} />
            <SwitchTransition>
                <CSSTransition key={currentFilter} classNames='fade' timeout={500}>
                    <FilteredList filteredList={filteredList} />
                </CSSTransition>
            </SwitchTransition>
        </div>
    )
}

const Filters = memo(({ possibleFilters, setCurrentFilter, currentFilter, articlesLength, showing, articles,allArticles }) => {
    const [open, setOpen] = useState(false)
    const { switchLanguage } = useLanguage()
    return (
        <>
            <div className='flex justify-between mb60 d-show '>
                <div className='flex '>
                    {possibleFilters.map(({ name, nameJp }) => (
                        <Filter menu={true} key={name} current={name === currentFilter || nameJp === currentFilter} filter={switchLanguage(nameJp, name)} onClick={setCurrentFilter} />
                    ))}
                </div>
                <div className=''>
                    <P>
                        {" "}
                        <span className='text-light-grey'>{showing}:</span> {articlesLength} {articles}
                    </P>
                </div>
            </div>
            <div className=' justify-between mb30 none m-flex z-3 pos-rel d-hide align-center '>
                <H3 className='f600'> {allArticles}</H3>
                <div className='pos-rel br-10' style={{ height: `${1 / possibleFilters.length}%` }}>
                    <button onClick={() => setOpen(state => !state)} className={"br-10 w-100 br10 bg-white  p15 flex justify-between align-center m0 " + (open ? "br-b0 bg-green text-white" : "")}>
                        <Filter textClassName='f600 ' wrapperAs='div' as={P} key={currentFilter} current={true} filter={currentFilter} setCurrentFilter={setCurrentFilter} />
                        <div className={"trans-transform " + (!open ? "fill-black" : "")} style={{ transform: !open ? "rotate(180deg)" : "rotate(0deg)" }}>
                            <Arrow />
                        </div>
                    </button>
                    <div className={"h-0 mtn1 " + (!open ? " overflow-hidden h-0" : "")}>
                        <div className={"pos-rel br-10 z-3 mbn100 right-0 z-3   br-t0  top-0 flex flex-col justify-center align-start p15 pt0 pb0 grid grid-cols-1 grid gap-y-15 pb15 " + (open ? "bg-green text-white" : "bg-white")}>
                            {possibleFilters.map(({ name, nameJp }) => (
                                <Filter
                                    textClassName={"m-text-left f600 "}
                                    className='m-mr20 text-left'
                                    as={P}
                                    key={name}
                                    current={false}
                                    filter={switchLanguage(nameJp, name)}
                                    onClick={(name, nameJp) => {
                                        setCurrentFilter(() => switchLanguage(nameJp, name))
                                        setOpen(false)
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

const Filter = memo(({ wrapperAs = "button", filter, onClick, current, className, as = H3, menu, textClassName }) => {
    const As = as
    const Wrapper = wrapperAs

    const onButtonClick = useCallback(() => {
        onClick(filter)
    }, [onClick, filter])

    return (
        <Wrapper className={"mr30  m-mr0 " + className + " " + (menu ? "underline-button " + (current ? "underline" : "text-light-grey  ") : "")} onClick={onClick ? onButtonClick : false}>
            <As className={textClassName}> {filter}</As>
        </Wrapper>
    )
})

const FilteredList = memo(({ filteredList }) => {
    return (
        <div className='grid grid-cols-3 gap-x-30 gap-y-60 m-grid-1 t-grid-2 m-gap-y-30 '>
            {filteredList.map(newsItem => {
                return <NewsCard className='m-mb0' key={newsItem.id} post={newsItem} />
            })}
        </div>
    )
})
